<template>
  <div class="aboutGroup">
      <Head @closeMenu="closeOpenMenu" />
      <Menu ref="menu" />
     
      <div class="banner">
                <!-- <div class="forward">
          </div> -->
      </div>
      <div class="contents" id="contents">
          <div class="contentCenter">
              <h3 class="title" id="title">集团简介</h3>
              <p class="lines">
                <span class="lineCenter"></span>
              </p>

              <div class="contextCenter">
                <div class="left"></div>
                <div class="right">
                   <h2 class="titles">ABOUT US</h2>
                   <p class="textContent">
                        祥业科技控股集团是数字化整体解决方案提供商和运营服务商。祥业科技控股伴随着新经济的发展使命，在“十四五”规划“加快数字化发展 建设数字中国”的政策背景下。打造了DI(数字基础设施)和DA(数据应用)两大业务方向，以DI-Edge(智能边缘)、DI-Cloud(数字云底座)、DA数据应用(政府治理与服务侧：G2G、G2B、G2C）为主干的三大产品和解决方案体系。集团不断加大技术研发投入，持续强化边/云/用三位一体的综合竞争力，不断提升和扩大数字与智能平台的品牌影响力。
                      <br>
                       集团旗下北京有为信通科技发展有限公司专注于数字基础设施建设领域，在全国五省布局，业务辐射华北、西北、华南，连续两年登上北京瞪羚企业榜单，被认定为北京科技中小型企业，同时是国家高新技术企业和中关村高新技术企业，取得了ISO9001、14001、18000、27001、45000的认证、信息系统集成认证、安防工程认证、音视频一级认证，在软件领域取得了CMMI3级软件成熟度认证，同时具备多项专利和软件著作权。
                       <br>
                         集团旗下北京华星数聚智慧有限公司专注于数字化应用领域。推出了数据治理、数据分析、数据交换等一系列产品及服务，同时推出了多个自主的数字化应用平台，数字城市管理平台、数字景区平台、数字园区平台、数字社区平台、数字科技馆、智慧应急平台、智慧路灯平台、智慧农业平台等。

                   </p>
                    <div class="EnName"></div>
                </div>
              </div>
          </div>
      </div>
        <Footer />
         <Topreturn @closeMenu="closeOpenMenu"></Topreturn>
  </div>
</template>

<script>
import Head from "@/components/head.vue";
import Footer from "@/components/footer.vue";
import Menu from "@/components/menu.vue";
import Topreturn from "@/components/Topreturn.vue";
import setScrollTop from "../../utils/setScrollTop.js";
export default {
 components: {
    Head,
    Menu,
    Footer,
    Topreturn
  },
    methods: {
    closeOpenMenu(t) {
      console.log(t);
      this.$refs.menu.menuShow = t;
    },
  },
   watch: {
    $route(to, from) {
      setScrollTop('title');
    },
  },
  mounted(){
    setScrollTop('title');
  },


}

</script>

<style scoped>
/* banner图开始 */
 .banner{
     width: 100%;
    height: 1080px;
    background-image: url("../../assets/image/aboutGroup/index/banner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
 }
 .banner .forward{
    width: 164px;
    height: 44px;
    background-image: url("../../assets/image/aboutGroup/index/BnnerContext.png");
     background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 58px;
    bottom: 26px;
 }
 /* banner图结束 */

 /* 主体内容开始 */
 .contents{
    width: 100%;
    height: 1080px;
    background-image: url("../../assets/image/aboutGroup/index/backgroundImage.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
 }
 .contents .contentCenter{
    padding-top: 78px;
    color: #fff;
    max-width: 1466px;
    margin: 0 auto;
 }
 .contents .contentCenter .title{
   font-size: 40px;
   font-family: IOS9W4;
   font-weight: bold;
   color: #17171C;
 }
 .contents .contentCenter .lines{
   margin-bottom: 36px;
 }
 .contents .contentCenter .lines .lineCenter{
    width: 70px;
    height: 4px;
    background: #3C4047;
    border-radius: 2px;
    display: inline-block;
 }
 .contents .contentCenter .contextCenter{
    color: #333333;
    display: flex;
    padding-top: 37px;
    justify-content: space-between;
 }
 .contents .contentCenter .contextCenter .left{
    width: 577px;
    text-align: left;
    display: inline-block;
    background-image: url("../../assets/image/aboutGroup/index/content.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-family: Microsoft YaHei;
    overflow: hidden;
    height: 796px;
 }
 .contents .contentCenter .contextCenter .right{
    width: 850px;
    display: inline-block;
    text-align: left;
 }
 .contents .contentCenter .contextCenter .right .titles{
   font-size: 50px;
   font-family: IOS9W4;
   font-weight: 400;
   color: #000508;
 }
  .contents .contentCenter .contextCenter .right .textContent{
    padding-top: 27px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 43px;
    height: 627px;
    font-size: 20px;
  }
  .contents .contentCenter .contextCenter .right .EnName{
    width: 739px;
    height: 58px;
    background-image: url("../../assets/image/aboutGroup/index/EnName.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
    margin-top: 26px;
  }

  /* hover 事件 */
 .contents .contentCenter .contextCenter .left:hover {
    background-size: 105% 105%;
    transition: 0.5s;
}
</style>